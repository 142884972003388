import React, { useState } from "react";
import { FaStar } from "react-icons/fa";

const StarRating = ({ rating, setRating }) => {
  const [hover, setHover] = useState(null);

  const starStyle = {
    cursor: "pointer",
    transition: "color 200ms",
  };
  const containerStyle = {
    display: "flex",
    marginTop: "10px",
  };

  return (
    <div style={containerStyle}>
      {[1, 2, 3, 4, 5].map((star) => (
        <FaStar
          key={star}
          size={24}
          color={star <= (hover || rating) ? "#FFD700" : "#e4e5e9"}
          onMouseEnter={() => setHover(star)}
          onMouseLeave={() => setHover(null)}
          onClick={() => setRating(star)}
          style={starStyle}
        />
      ))}
    </div>
  );
};

export default StarRating;